<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <input
        type="file"
        ref="image"
        style="display: none"
        accept="image/*"
        @change="onFilePicked"
      />
      <ModelTitle title="Images" @close="$emit('close')" />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn :loading="loading" block color="info" @click="pickFile"
              >File From Computer</v-btn
            >
          </v-col>
          <v-col cols="12" class="ma-0 pa-0 text-center">OR</v-col>
          <v-col>
            <v-text-field
              v-model="loadUrl"
              outlined
              clearable
              @keyup.enter="loadImage"
              ref="imageUrl"
              :error-messages="urlError"
              :hide-details="urlError.length === 0"
              @focus="$event.target.select()"
              dense
              label="Enter URL"
            ></v-text-field>
          </v-col>
          <v-col class="shrink">
            <v-btn color="success" @click="loadImage">Load</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="images.length > 0">
        <v-row>
          <v-col
            v-for="n in images"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-card flat class="d-flex" hover>
              <v-img
                @click="select(n)"
                :src="n"
                :lazy-src="n"
                aspect-ratio="1"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <div v-if="imageResult">
          <v-icon v-if="imageResult.searchType === 'database'"
            >mdi-database</v-icon
          >
          <v-icon v-else>mdi-web</v-icon>
        </div>

        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('close')">
          {{ $t("buttons.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const { validationMixin } = require("vuelidate");
const { url, required } = require("vuelidate/lib/validators");
import attachmentService from "@/modules/Attachment/service.js";

export default {
  props: ["imageResult", "showDialog", "field"],
  data() {
    return {
      loading: false,
      loadUrl: null,
      dialog: false,
      images: [],
      imageName: "",
      imageUrl: "",
      imageFile: "",
    };
  },
  mixins: [validationMixin],
  validations: {
    loadUrl: {
      required,
      url,
    },
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.imageUrl.focus();
        }, 100);
      }
      // if (this.imageResult) {
      //   this.images = [];
      //   this.images.push(this.imageResult.imgUrl1);
      //   this.images.push(this.imageResult.imgUrl2);
      //   this.images.push(this.imageResult.imgUrl3);
      //   this.images.push(this.imageResult.imgUrl4);
      //   this.images.push(this.imageResult.imgUrl5);
      // }
    },
    imageResult() {
      this.images = [];
      this.images.push(this.imageResult.imgUrl1);
      this.images.push(this.imageResult.imgUrl2);
      this.images.push(this.imageResult.imgUrl3);
      this.images.push(this.imageResult.imgUrl4);
      this.images.push(this.imageResult.imgUrl5);
    },
  },
  computed: {
    urlError() {
      const errors = [];
      if (!this.$v.loadUrl.$dirty) return errors;
      if (!this.$v.loadUrl.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.loadUrl.url) errors.push("Valid URL");
      return errors;
    },
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.loading = true;
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
          return attachmentService
            .upload({
              base64: this.imageUrl,
              field: this.field,
              name: this.imageName,
            })
            .then((result) => {
              if (result.status) {
                this.images.push(result.data);
                this.loading = false;
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  .fire({
                    icon: "success",
                    title: "Image is Uploaded!",
                  });
                // this.loading = false;
                // this.$emit("submit");
                // this.close();
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    loadImage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        this.images.push(this.loadUrl);
        this.loadUrl = null;
        this.$v.$reset();
        setTimeout(() => {
          this.$refs.imageUrl.focus();
        }, 100);
      }
    },
    select(val) {
      this.$emit("submit", val);
    },
  },
};
</script>

<style scoped></style>
