<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <Create :createDialog="categoryCreateDialog" @close="categoryCreateDialog = false" @submit="getCategory"></Create>
    <DepartmentCreate :createDialog="departmentCreateDialog" @close="departmentCreateDialog = false" @submit="getDepartment"></DepartmentCreate>
    <BrandCreate :createDialog="brandCreateDialog" @close="brandCreateDialog = false" @submit="getBrand"></BrandCreate>
    <v-card grid-list-md>
      <v-toolbar dense class="mb-3" flat color="grey lighten-3">
        <v-toolbar-title class="subtitle-1">New Product</v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-checkbox label="Product Active" hide-details outlined dense class="mr-2"
          v-model="Product.active"></v-checkbox>
        <v-btn small icon @click.stop.prevent="close()">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <ModelTitle title="New Product" @close="close()" /> -->
      <v-container>
        <v-row>
          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <ImageView :name="searchName" field="product" :initUrl="Product.imageUrl" @submit="imageChange" />
              </v-col>
              <v-col sm="12" md="9">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field v-model.trim="Product.name" :error-messages="nameErrors"
                      :hide-details="nameErrors.length === 0" outlined @change="changeName"
                      @focus="$event.target.select()" dense autofocus label="Name" ref="productName"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-select :items="Category" clearable :hide-details="selectCategoryError.length === 0"
                      :error-messages="selectCategoryError" v-model="Product.CategoryId" item-text="name" outlined dense
                      item-value="id" label="Select Category"><template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="categoryCreateDialog = true">
                          <v-list-item-content>
                            <v-list-item-title>Create New Category</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template></v-select>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-select :items="Department" clearable hide-details v-model="Product.DepartmentId" item-text="name" outlined dense
                      item-value="id" label="Select Department"><template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="departmentCreateDialog = true">
                          <v-list-item-content>
                            <v-list-item-title>Create New Department</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template></v-select>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-select :items="Brand" clearable hide-details v-model="Product.BrandId" item-text="name" outlined dense
                      item-value="id" label="Select Brand"><template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item @click="brandCreateDialog = true">
                          <v-list-item-content>
                            <v-list-item-title>Create New Brand</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template></v-select>
                  </v-col>
                  <div v-if="currentBusiness.metadata && currentBusiness.metadata.parentChile">
                    <v-col cols="12" sm="12" v-if="!Product.isParent">
                      <v-select v-if="Products" :items="Products" clearable v-model="Product.ChildProductId"
                        hide-no-data item-text="name" outlined dense item-value="id"
                        label="Select Parent Product"><template v-slot:append-item>
                        </template>
                      </v-select>
                    </v-col>
                  </div>

                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-text-field v-model="Product.upc" @focus="$event.target.select()" outlined
              @click:append="generateRandomUPC" append-icon="mdi-barcode-scan" hide-details dense
              label="UPC Code"></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-text-field v-model="Product.cost" @focus="$event.target.select()" class="right-input" outlined
              hide-details dense label="Purchase Cost"></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-text-field v-model="Product.price" class="right-input" label="Price" outlined dense hide-details
              @focus="$event.target.select()"></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-text-field v-model="Product.balance" class="right-input" hide-details disabled outlined dense
              @focus="$event.target.select()" label="Current Stock"></v-text-field>
          </v-col>
        </v-row>
        <div v-if="Product.trackInventory">
          <v-subheader>Reorder Point</v-subheader>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-text-field v-model="Product.min" class="right-input" label="Min" outlined dense hide-details
                @focus="$event.target.select()"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field v-model="Product.max" class="right-input" label="Max" outlined dense hide-details
                @focus="$event.target.select()"></v-text-field>
            </v-col>
          </v-row>
          <div v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
            <v-subheader>Unit count in the box</v-subheader>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <v-text-field type="number" v-model="Product.unitCountInBox" class="right-input" label="Unit Count"
                  outlined dense hide-details @focus="$event.target.select()"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field type="number" readonly v-model="Product.boxPrice" class="right-input" label="Box Price"
                  outlined dense hide-details @focus="$event.target.select()"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-row align="center">
          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-checkbox class="mt-0" label="Track Inventory" hide-details outlined dense :disabled="trackInventory"
              v-model="Product.trackInventory"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="12" md="4" v-if="currentBusiness.metadata && currentBusiness.metadata.serializeProduct">
            <v-checkbox class="mt-0" label="Track Serial Number" hide-details outlined dense
              v-model="Product.trackSerialNumber">
            </v-checkbox>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="4">
            <v-checkbox class="mt-0" label="Product Active" hide-details outlined dense
              v-model="Product.active"></v-checkbox>
          </v-col> -->
          <v-col cols="12" sm="12" md="4" v-if="!Product.isParent">
            <v-checkbox class="mt-0" label="Remote Ordering" hide-details outlined dense
              v-model="Product.remoteOrder"></v-checkbox>
          </v-col>
          <v-col cols="12" sm="12" md="4" >
            <v-checkbox label="Mark Special" hide-details outlined dense v-model="Product.special"></v-checkbox>
          </v-col>
          <v-col v-if="currentBusiness.metadata && currentBusiness.metadata.parentChile">
            <v-checkbox class="mt-0" label="Parent Product" hide-details outlined dense
              :disabled="Product.trackSerialNumber" v-model="Product.isParent"></v-checkbox>
            <div class="caption">As a Parent Product, This Item Will No Longer Be Available for Sale, But You Can Add
              and Manage
              Sub-Products Under It</div>
          </v-col>
          <v-col v-if="!Product.isParent">
            <v-checkbox class="mt-0" label="Apply Weight" hide-details outlined dense
              v-model="Product.metadata.weightApply">
            </v-checkbox>
            <div>Weight will ask on this product sale and multiply with sale price</div>
          </v-col>
          <v-col cols="12" sm="12" md="4" v-if="currentBusiness.metadata && currentBusiness.metadata.dynamicPrice">
            <v-checkbox label="Standard Pricing" hide-details outlined dense
              v-model="Product.metadata.standardPrice"></v-checkbox>
          </v-col>
        </v-row>



        <v-row>
          <v-col cols="12" sm="12">
            <v-textarea counter="counter" hide-details outlined dense auto-grow v-model="Product.note"
              :label="$t('labels.description')"></v-textarea>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <v-card-actions>
        <!-- <MoreImage :imageResult="imageResult" @submit="imageChange" /> -->

        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text="text" @click="close()">Close</v-btn>
        <v-btn color="blue darken-1" :loading="loading" dark @click="submit()">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import productService from "../service.js";
import categoryService from "@/modules/Product/Category/service";
import departmentService from "@/modules/Product/Department/service";
import brandService from "@/modules/Product/Brand/service";
import GlobalFunction from "@/mixins/GlobalFunction";
import ImageView from "@/components/Image/ImageView";
import { mapGetters } from "vuex";

const { validationMixin } = require("vuelidate");
const { required, minLength, minValue } = require("vuelidate/lib/validators");
const Create = () => import("@/modules/Product/Category/components/Create");
const DepartmentCreate = () => import("@/modules/Product/Department/components/Create");
const BrandCreate = () => import("@/modules/Product/Brand/components/Create");

export default {
  props: {
    createDialog: {
      default: false,
    },
  },
  name: "create-product",
  data() {
    return {
      selectedProduct: null,
      productSearchLoading: false,
      Products: [],
      categoryCreateDialog: false,
      departmentCreateDialog: false,
      brandCreateDialog: false,
      Category: [],
      Department: [],
      Brand: [],
      searchName: null,
      imageResult: null,
      selectedCategory: null,
      loading: false,
      dialog: false,
      trackInventory: false,
      Product: {
        upc: null,
        isParent: false,
        name: null,
        note: null,
        imageUrl: null,
        price: null,
        cost: 0,
        boxPrice: null,
        unitCountInBox: null,
        trackSerialNumber: false,
        balance: 0,
        trackInventory: false,
        min: null,
        max: null,
        active: true,
        metadata: {
          weightApply: false
        }
      },
    };
  },
  mixins: [validationMixin, GlobalFunction],
  validations: {
    Product: {
      name: {
        required,
        minLength: minLength(4),
      },
      CategoryId: {
        required,
        minValue: minValue(1),
      },
    },
  },
  components: {
    // MoreImage,
    ImageView,
    Create,
    BrandCreate,
    DepartmentCreate,
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.Product.name.$dirty) return errors;
      if (!this.$v.Product.name.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Product.name.minLength)
        errors.push("Field must be at least 4 characters long");
      return errors;
    },
    selectCategoryError() {
      const errors = [];
      if (!this.$v.Product.CategoryId.$dirty) return errors;
      if (!this.$v.Product.CategoryId.required)
        errors.push(this.$t("validations.fieldIsRequired"));
      if (!this.$v.Product.CategoryId.minValue)
        errors.push("Pleaes select category");

      return errors;
    },
  },
  watch: {
    'Product.isParent': function (val) {
      if (!val) {
        this.selectedProduct = null
      }
    },
    'Product.unitCountInBox': function () {
      this.updateboxPrice();
    },
    'Product.price': function () {
      this.updateboxPrice();
    },
    Product: {
      handler(val) {
        if (val.trackSerialNumber) {
          this.Product.trackInventory = true;
          this.trackInventory = true;
        } else {
          this.trackInventory = false;
        }
      },
      deep: true,
    },
    // Product: {
    //   handler(val) {
    //     // console.log('val', val)
    //     // if (val.trackInventory) {
    //     //   if (val.price && val.unitCountInBox) {
    //     //     val.boxPrice = val.price * val.unitCountInBox
    //     //   }
    //     //   if (val.boxPrice && val.unitCountInBox) {
    //     //     console.log('in heer', val.boxPrice, val.unitCountInBox, val.price)
    //     //     val.price = val.boxPrice / val.unitCountInBox
    //     //   }
    //     // }

    //     if (val.trackSerialNumber) {
    //       this.Product.trackInventory = true;
    //       this.trackInventory = true;
    //     } else {
    //       this.trackInventory = false;
    //     }
    //   },
    //   deep: true,
    // },
    createDialog(val) {
      this.dialog = val;
      if (val) {
        this.getCategory();
        this.getDepartment();
        this.getBrand();
        this.$nextTick(() => {
          this.$refs.productName.focus();
          console.log('this.custo', this.currentBusiness.metadata.ForcedInventoryTracking)
          if (this.currentBusiness?.metadata?.ForcedInventoryTracking) {
            this.Product.trackInventory = true;
            this.trackInventory = true;
          }
        });

      }
    },
  },
  methods: {
    generateRandomUPC() {
      let upc = 'C'; // Start with 'C'
      for (let i = 1; i < 12; i++) { // Start the loop from 1 because 'C' is already added
        upc += Math.floor(Math.random() * 10); // Generate a random digit (0-9)
      }
      this.Product.upc = upc;
    },
    getProduct() {
      this.loading = true;

      return productService.getAll({ isParent: true }).then((result) => {
        console.log('get prodcut', result)
        this.Products = result.data;
        this.loading = false;
      });
    },
    updateboxPrice() {
      if (
        this.Product.trackInventory &&
        this.Product.unitCountInBox > 0 &&
        this.Product.price > 0
      ) {
        this.Product.boxPrice = (this.Product.price * this.Product.unitCountInBox).toFixed(2);
        this.Product.boxPrice = parseFloat(this.Product.boxPrice);
      }
    },
    imageChange(val) {
      // console.log("image change ", val);
      if (val) {
        this.Product.imageUrl = val;
      }
    },
    changeName() {
      this.Product.name = this.properCase(this.Product.name);
      this.searchName = this.Product.name;
      // this.getimage(this.Product.name).then(response => {
      //   console.log('response', response)
      //   if (response.data) {
      //     this.imageResult = response.data;
      //     this.Product.imageUrl = response.data.imgUrl1;
      //   }
      // });
    },
    getimage(name) {
      return productService
        .getGooglePic({ name: name })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    close() {
      this.empty();
      this.$emit("close");
    },
    getCategory() {
      this.loading = true;
      return categoryService.getAll().then((response) => {
        this.loading = false;
        this.Category = response.data;
        return response;
      });
    },
    getDepartment() {
      this.loading = true;
      return departmentService.getAll().then((response) => {
        this.loading = false;
        this.Department = response.data;
        return response;
      });
    },
    getBrand() {
      this.loading = true;
      return brandService.getAll().then((response) => {
        this.loading = false;
        this.Brand = response.data;
        return response;
      });
    },
    empty() {
      this.Product = {
        isParent: false,
        name: null,
        note: null,
        price: null,
        cost: 0,
        boxPrice: null,
        unitCountInBox: null,
        min: null,
        max: null,
        imageUrl: null,
        CategoryId: null,
        trackSerialNumber: false,
        balance: 0,
        trackInventory: false,
        active: true,
        metadata: {
          weightApply: false
        },
      };
      this.$v.$reset();
    },
    submit() {
      if (!this.Product.min && this.Product.max) {
        // console.log('in here')
        this.Product.min = parseInt(this.Product.max / 2);
      }

      if (!this.Product.max && this.Product.min) {
        // console.log('in here')
        this.Product.max = parseInt(this.Product.min * 2);
      }

      if (this.Product.max < this.Product.min) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Max balance can't more then min balance",
          });
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: this.$t("toastMessage.text.invalidFields"),
          });
      } else {
        if (this.imageUrl) {
          this.Product.image = {
            imageUrl: this.imageUrl,
            TypeId: 9,
          };
        }
        this.loading = true;
        if (this.Product.isParent) {
          this.Product.trackInventory = false
          this.Product.trackSerialNumber = false
          this.Product.ChildProductId = null
        }

        return productService
          .create(this.Product)
          .then((result) => {
            if (result.status) {
              this.$swal
                .mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                })
                .fire({
                  icon: "success",
                  title: "Product is created",
                });
              this.empty();
              this.loading = false;
              this.$emit("submit");
            }
          })
          .catch((err) => {
            // console.log("error", err);
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
  },
};
</script>

<style scoped></style>
