import backofficeService from '../../api/backoffice'

export default {
  /**
   * Get list of products
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to products
   */
  getAll(params) {
    return backofficeService.Api().get('image', { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Get specific product
   * @param {*} id
   * @param {*} params
   * @param {*} params.local Add LocalData to product
   */
  getById(id, params = {}) {
    return backofficeService.Api().get(`image/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  
  /**
   * Create a product. Will also initiate location data
   * @param {*} data
   */
  create(data) {
    return backofficeService.Api().post('image', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  upload(data) {
    return backofficeService.Api().post('image/upload', data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Update specific product
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    return backofficeService.Api().put(`image/${id}`, data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Delete product
   * @param {} id
   * @param {*} params
   */
  delete(id, params) {
    return backofficeService.Api().delete(`image/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },

}
