<template>
  <v-card height="100%" min-height="100" outlined>
    <v-hover v-if="imageUrl" v-slot:default="{ hover }">
      <v-img height="100%" aspect-ratio="1" :src="imageUrl">
        <v-layout v-if="hover" column fill-height justify-center align-center>
          <v-flex shrink>
            <v-btn @click="imageRemove" color="error" small> Remove </v-btn>
          </v-flex>
          <v-flex class="mt-1" shrink>
            <v-btn @click="openMore" color="purple" dark small>
              <span v-if="imageResult">More Image</span>
              <span v-else>Add Image</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-img>
    </v-hover>
    <v-hover v-else v-slot:default="{ hover }">
      <v-img height="100%" src="@/assets/images/NoPhotoAvailable.jpg">
        <v-layout v-if="hover" column fill-height justify-center align-center>
          <v-flex shrink>
            <v-btn @click="openMore" color="purple" dark small>
              <span v-if="imageResult">More Image</span>
              <span v-else>Add Image</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-img>
    </v-hover>
    <MoreImage
      :field="field"
      :showDialog="showDialog"
      :imageResult="imageResult"
      @close="showDialog = false"
      @submit="imageChange"
    />
  </v-card>
</template>

<script>
import MoreImage from "@/components/Image/MoreImage";
import productService from "@/modules/Product/service.js";

export default {
  props: ["name", "field", "initUrl"],
  data() {
    return {
      showDialog: false,
      imageUrl: null,
      imageResult: null,
    };
  },
  components: {
    MoreImage,
  },
  watch: {
    initUrl(val) {
      this.imageUrl = val;
    },
    imageUrl(val) {
      if (val) this.$emit("submit", val);
    },
    // name(val) {
    //   this.getimage(val).then((response) => {
    //     // console.log('responseresponse', response)
    //     if (response && response.data) {
    //       this.imageResult = response.data;
    //       this.imageUrl = response.data.imgUrl1;
    //     }
    //   });
    // },
  },
  methods: {
    openMore() {
      this.showDialog = true;
    },
    imageRemove() {
      this.imageUrl = null;
      this.$emit("submit", this.imageUrl);
    },
    getimage(name) {
      return productService
        .getGooglePic({ name: name })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    imageChange(val) {
      this.imageUrl = val;
      this.showDialog = false;
    },
  },
};
</script>

<style scoped></style>
